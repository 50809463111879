import guidUtils from '@wix/santa-core-utils/dist/cjs/coreUtils/core/guidUtils';
import { TOKEN } from './constants';

export const getAllComponents = async (sdk) => {
  const allComponents = await sdk.components.getAllComponents(TOKEN);
  return Promise.all(
    allComponents.map((componentRef) =>
      Promise.all([
        sdk.components.getType(TOKEN, { componentRef }),
        sdk.components.getAncestors(TOKEN, { componentRef }),
      ]).then(([type, ancestors]) => ({ type, componentRef, containerRef: ancestors[0] })),
    ),
  );
};

export const getSerializedComponents = async ({ sdk, predicate = () => true, maintainIdentifiers = true }) => {
  const components = (await getAllComponents(sdk)).filter(predicate);

  return Promise.all(
    components.map(({ componentRef, containerRef }) =>
      sdk.document.components
        .serialize(TOKEN, { componentRef, maintainIdentifiers })
        .then((serialized) => ({ serialized, componentRef, containerRef })),
    ),
  );
};

const getUniqueSectionId = (section) => async (sdk) => {
  for (let i = 0; i < 10; i++) {
    const sectionId = `${section}_${guidUtils.getUniqueId()}`;
    const isSectionIdTaken = Boolean(await sdk.document.components.getById(TOKEN, { id: sectionId }));
    if (!isSectionIdTaken) {
      return sectionId;
    }
  }

  throw new Error('cannot generate an unique section id');
};

export const getUniqueSectionIdForFeedPage = getUniqueSectionId('TPASection');
export const getUniqueSectionIdForPostPage = getUniqueSectionId('TPAMultiSection');
