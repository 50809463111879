export const SECTIONS = {
  ABOUT: 'about',
  FOLLOWERS: 'followers',
  NOTIFICATIONS: 'notifications',
  SETTINGS: 'settings',
  POSTS: 'posts',
  COMMENTS: 'comments',
  LIKES: 'likes',
  DRAFTS: 'drafts',
};
